import { call, put } from "redux-saga/effects";
import api from "../../util/api";
import { PRINT_TAGS_URL, OFFICE_REPLENISHMENT_PO_URL } from "../../util/constants/api.constants";
import { toQueryParam } from "../../util/utils";
import {
  fetchOfficeReplenishmentPOListFailed,
  fetchOfficeReplenishmentPOListSuccess,
} from "./actions";

export function* fetchOfficeReplenishmentPO({ payload }: any): Generator {
  try {
    let url = OFFICE_REPLENISHMENT_PO_URL.OFFICE_REPLENISHMENT_PO_LIST;
    const data: any = yield call(api.post, url, payload);
    yield put(fetchOfficeReplenishmentPOListSuccess(data));
  } catch (e: any) {
    put(fetchOfficeReplenishmentPOListFailed({ message: e.message }));
  }
}

export function officeReplenishmentPOVendorList(payload: any) {
  let url = OFFICE_REPLENISHMENT_PO_URL.OFFICE_REPLENISHMENT_PO_LIST;
  return api.get(url, payload);
}

export function getOffices(payload: any) {
  let url = `${PRINT_TAGS_URL.ACTIVEOFFICES}`;
  return api.get(url);
}
