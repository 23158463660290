import { actionKeyGenerate, ACTION, GenericObject } from "../../util/utils";

export const MODULE_KEY = "OFFICE_REPLENISHMENT_PO";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);

export const OFFICE_REPLENISHMENT_PO_FETCH = KEYS[MODULE_KEY + ACTION.FETCH]

export const fetchOfficeReplenishmentPOList = (payload: any) => ({
    type: OFFICE_REPLENISHMENT_PO_FETCH,
    payload,
});

export const fetchOfficeReplenishmentPOListSuccess = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.SUCCESS],
    payload,
});

export const fetchOfficeReplenishmentPOListFailed = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.FAILED],
    payload,
});
