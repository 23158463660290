import PrintTags from "./components/printTags/PrintTags";
import OpenPull from "./components/openPulls/OpenPull";
import FramePick from "./components/framePick/FramePick";
import Vendor from "./components/vendor/Vendor";
import Replenishment from "./components/Replenishment/Replenishment";
import DailyOrders from "./components/orderStatus/DailyOrders";
import OrderStatus from "./components/orderStatus/OrderStatus";
import VendorOrders from "./components/vendorOrders/VendorOrders";
import PendingOrder from "./components/vendorOrders/PendingOrder";
import AccessoryReplenishment from "./components/systemPreference/AccessoryReplenishment";
import KitMaintenance from "./components/kits/kitMaintenance/KitMaintenance";
import NewStyleKit from "./components/kits/kitMaintenance/NewStyleKit";
import FrameArrangerQty from "./components/FrameArranger/FrameArrangerQty/FrameArrangerQty";
import styles from "./components/navbar/navbarMenu.module.scss";
import VendorsAndCollection from "./components/FrameArranger/VendorsAndCollections/VendorsAndCollection";
import ProductPlanogram from "./components/FrameArranger/ProductPlanogram/ProductPlanogram";
import OfficeCollectionMapping from "./components/FrameArranger/OfficeCollection/OfficeCollectionMapping";
import { reactApp } from "./util/constants/common.constants";
import ValidateAcuityFrameImport from "./components/FrameArranger/ValidateAcuityFrameImport/ValidateAcuityFrameImport";
import KitProcessing from "./components/kits/KitProcessing/KitProcessing";
import ProcessNewKit from "./components/kits/KitProcessing/ProcessNewKit";
import KitDetailPage from "./components/kits/kitMaintenance/KitDetailPage";
import FedEx from "./components/FedEx/Fedex/FedEx";
import ExpressOffices from "./components/FedEx/ExpressOffices/ExpressOffices";
import Dashboard from "./components/Dashboard/Dashboard";
import Unauthorized from "./components/unauthorized/unauthorized";
import BulkCostUpdate from "./components/bulkCostUpdate/BulkCostUpdate";
import OfficeReplenishmentPO from "./components/officeReplenishmentPO/officeReplenishmentPO";

const routes = [
    {
        path: "/dashboard",
        title: "Dashboard",
        content: () => <Dashboard />,
    },
    {
        path: "/framePick",
        title: "Frame Pick",
        content: () => <FramePick />,
    },
    {
        title: "Replenishment",
        children: [
            {
                title: "DC",
                children: [
                    {
                        path: "/replenishment",
                        title: "Vendor Order",
                        content: () => <Replenishment />,
                    },
                    {
                        path: "/vendorOrders",
                        title: "Vendor Receipt",
                        content: () => <VendorOrders />,
                    },
                    {
                        path: "/pendingBackorder",
                        title: "Vendor Backorders",
                        content: () => <PendingOrder />,
                    },
                ]
            },
            {

                path: "/officereplenishmentpo",
                title: "Office",
                content: () => <OfficeReplenishmentPO />,
            },
            {
                path: "/replenishmentPreferences",
                title: "Accessories",
                content: () => <AccessoryReplenishment />,
            },
        ],
    },
    {
        title: "Frame Arranger",
        children: [
            {
                path: "/vendorsCollectionsMapping",
                title: "Vendors to Collections Mapping",
                content: () => <VendorsAndCollection />,
            },
            {
                path: "/assortmentManagement",
                title: "Assortment Management",
                content: () => <FrameArrangerQty />,
            },
            {
                path: "/officeCollectionsMapping",
                title: "Office Planograms",
                content: () => <OfficeCollectionMapping />,
            },
            {
                path: "/productPlanogram",
                title: "Product Planogram",
                content: () => <ProductPlanogram />,
            },
            {
                path:
                    reactApp().reportDomainName +
                    "FrameArranger/FRAME_ItemImportIntoAcuity",
                title: "Planogram Export",
            },
            {
                path: "/ValidateAcuityFrameImport",
                title: "Validate Acuity Frame Import",
                content: () => <ValidateAcuityFrameImport />,
            },
        ],
    },
    {
        title: "New Style Kits",
        children: [
            {
                path: "/kitMaintenance",
                title: "Kit Maintenance",
                content: () => <KitMaintenance />,
            },
            {
                path: "/KitProcessing",
                title: "Kit Processing",
                content: () => <KitProcessing />,
            },
        ],
    },
    {
        path: "/newKit",
        title: "",
        content: () => <NewStyleKit />,
    },
    {
        path: "/processNewKit",
        title: "",
        content: () => <ProcessNewKit />,
    },
    {
        path: "/kitDetailPage",
        title: "",
        content: () => <KitDetailPage />,
    },
    {
        title: "Reports",
        children: [
            {
                path:
                    reactApp().reportDomainName +
                    "FrameArranger/FrameArrangerLocationInventoryTransfers",
                title: "Frame Arranger - Location Inventory Transfers",
            },
            {
                path:
                    reactApp().reportDomainName +
                    "FrameArranger/FrameArrangerLocationOnHandInventory",
                title: "Frame Arranger - Location On Hand Inventory",
            },
            {
                path:
                    reactApp().reportDomainName +
                    "FrameArranger/FrameArrangerLocationVendorCollection",
                title: "Frame Arranger - Location Vendor Collection",
            },
            {
                path:
                    reactApp().reportDomainName +
                    "Replenishment Reports/ReplenishmentCP",
                title: "Frame Arranger - Replenishment CP",
            },
            {
                path:
                    reactApp().reportDomainName +
                    "Replenishment Reports/ReplenishmentDCT",
                title: "Frame Arranger - Replenishment DCT",
            },
            {
                path:
                    reactApp().reportDomainName +
                    "Replenishment Reports/ReplenishmentStockTransfer",
                title: "Frame Arranger - Replenishment Stock Transfer",
            },
            {
                path:
                    reactApp().reportDomainName +
                    "Replenishment Reports/ReplenishmentVendorOrder",
                title: "Frame Arranger - Replenishment Vendor Order",
            },
        ],
    },
    {
        title: "Utilities",
        children: [
            {
                path: "/expressOffices",
                title: "FedEx Express Offices",
                content: () => <ExpressOffices />,
            },
            {
                path: "/fedex",
                title: "FedEx Shipping Labels",
                content: () => <FedEx />,
            },
            {
                path: "/openPull",
                title: "Open Pull",
                content: () => <OpenPull />,
            },
            {
                path: "/printTags",
                title: "Print Little Tags",
                content: () => <PrintTags />,
            },
            {
                path: "/orders",
                title: "View Orders",
                content: () => <OrderStatus />,
            },
            {
                path: "/vendor",
                title: "Vendor Assignment",
                content: () => <Vendor />,
            },
            {
                path: "/orderSearch",
                title: "Update Order Status",
                content: () => <DailyOrders />,
            },
            {
                path: "/bulkCostUpdate",
                title: "Bulk Cost Update",
                content: () => <BulkCostUpdate />,
            },

        ],
    },
    {
        path: "/unauthorized",
        title: "",
        content: () => <Unauthorized />,
    },
];

export default routes;
