import { Divider, Modal, Select, Spin} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchFramePickList, fetchPicker, MODULE_KEY} from "./actions";
import "./../../styles/OpenPull.scss";
import useTableProps from "../../hooks/table/useTableProps";
import TableFilter from "../common/TableFilter/TableFilter";
import Label from "../common/Label/Label";
import Button from "../common/Button/Button";
import {
  printLabelReport,
  reprintLabelsReport,
  updateAcuityStatus,
  updatePrintLabel,
} from "./sagas";
import { FRAME_PICK_DATA_TYPE } from "../../util/columns";
import PDFStream from "../common/PDFStream/PDFStream";
import "./../../styles/FramePick.scss";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import filterColumns from "./filterColumns";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import TextArea from "antd/es/input/TextArea";
import { getUserName } from "../common/UserDetails";
import { convertStrToArr } from "src/util/utils";
import useRolePermission from "src/hooks/useRolePermission";
import GenericGrid from "../common/Grid/GenericGrid";
import { MRT_RowSelectionState } from "material-react-table";


const { Option } = Select;
let memoizePickerID = "";
const notification = new Notification("Frame Pick");
function FramePick() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [notFoundRow, setNotFoundRow] = useState<any>([]);
  const [stream, setStream] = useState<any>("");
  const tableProps = useTableProps(MODULE_KEY, [], "OrderNum");
  const userName = getUserName();
  const [label, setLabel] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [pickerId, setPickerId] = useState<any>(memoizePickerID || undefined);
  const [reprintModal, setReprintModal] = useState(false);
  const [orderList, setOrderList] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isReprintEnable, setIsReprintEnable] = useState(false);
  const { hasPermission } = useRolePermission();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const filterValues = filterColumns({
    selectedRow,
    setSelectedRow,
    notFoundRow,
    setNotFoundRow,
    rowSelection,
    setRowSelection
  });
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  useEffect(() => {
    const selectedIds = Object.keys(rowSelection).filter(id => rowSelection[id]);
    const selectedRows = selectedIds.map(index => filterValues.data[parseInt(index)]);
    let newRows = notFoundRow.filter(
      (notFoundSelectedRow: FRAME_PICK_DATA_TYPE) =>
        !selectedRows.find(
          (row: FRAME_PICK_DATA_TYPE) =>
            row.OrderNum == notFoundSelectedRow.OrderNum
        )
    );
    setNotFoundRow(newRows);
    setSelectedRow(selectedRows);
  }, [rowSelection]);

  const changePicker = (id: string) => {
    setPickerId(id);
    setSelectedRow([]);
    setNotFoundRow([]);
    memoizePickerID = id;
  };
  const handleGridData = (pickerId: any) => {
    const payload = {
      pickerId,
      isHoliday: true,
      offset: 0,
      limit: 100000,
      currentUser: userName,
    };
    dispatch(fetchFramePickList(payload));
  };

  useEffect(() => {
    if (pickerId || pickerId === 0) {
      handleGridData(pickerId);
    }
  }, [pickerId]);

  const acuityClickHandler = async () => {
    setLoader(true);
    try {
      const acuityData: any = [];
      selectedRow.forEach(
        ({ OfficeNum, OrderNum, ItemID }: FRAME_PICK_DATA_TYPE) => {
          acuityData.push({
            OfficeNumber: OfficeNum,
            OrderNumber: OrderNum,
            ItemID,
            ModifiedBy: userName,
            IsFound: true,
          });
        }
      );
      notFoundRow.forEach(
        ({ OfficeNum, OrderNum, ItemID }: FRAME_PICK_DATA_TYPE) => {
          acuityData.push({
            OfficeNumber: OfficeNum,
            OrderNumber: OrderNum,
            ItemID,
            ModifiedBy: userName,
            IsFound: false,
          });
        }
      );

      const res = await updateAcuityStatus(Object.values(acuityData));
      if (res) {
        showSuccessNotification(
          "Successfully updated the order status in Acuity."
        );
        setSelectedRow([]);
        setRowSelection({});
        setNotFoundRow([]);
        setOpen(false);
        setLoader(false);
        handleGridData(pickerId);
      } else {
        showErrorNotification(
          "Error occurred while updating the order status in Acuity."
        );
        setLoader(false);
        setOpen(false);
      }
    } catch (err) {
      showErrorNotification(
        "Error occurred while updating the order status in Acuity."
      );
      setLoader(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    dispatch(fetchPicker({}));
  }, []);

  const printLabelHandler = async (printType: any) => {
    setLoader(true);
    let records = [];
    setLabel(printType);
    if (printType === "all") {
      records = filterValues.data;
    } else {
      records = selectedRow;
    }
    try {
      const payload = {
        IdList: records.map((row: any) => row.Id),
        IsPrinted: false,
        PickerId: pickerId,
        currentUser: userName,
      };
      const res = await updatePrintLabel(payload);
      if (res) {
        const reportPayload = {
          reportName: "LabelFormNTEST",
          parameters: {
            Picker: pickerId,
          },
        };
        const printRes = await printLabelReport(reportPayload);
        if (printRes) {
          setStream(printRes);
        }
      }
    } catch (error) {
      showErrorNotification(
        undefined,
        "Print label request was not successful. Please try again"
      );
    }
    setLoader(false);
    setRowSelection({});
  };

  const onPrint = async () => {
    setLoader(true);
    try {
      let records = [];
      if (label === "all") {
        records = filterValues.data;
      } else {
        records = selectedRow;
      }
      const payload = {
        IdList: records.map((row: any) => row.Id),
        IsPrinted: true,
        PickerId: pickerId,
        currentUser: userName,
      };
      const res = await updatePrintLabel(payload);
      setStream("");
      setSelectedRow([]);
      setRowSelection({});
      setNotFoundRow([]);
      showSuccessNotification(res);
    } catch (error) {
      showErrorNotification(
        "Print label request was not successful. Please try again"
      );
    }
    setLoader(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setIsReprintEnable(false);
    setStream("");
    setSelectedRow([]);
    setNotFoundRow([]);
  };
  const handleOrderList = () => {
    if (orderList?.length) {
      setLoader(true);
      const odNum = convertStrToArr(orderList);
      const strExist = odNum?.filter((item: any) => isNaN(item));
      if (odNum?.map(Number)?.includes(NaN)) {
        setErrorMessage(
          `The following order numbers appear to be invalid, please check them and try again: ${strExist?.join(
            ", "
          )}`
        );
        showErrorNotification(
          `The following order numbers appear to be invalid, please check them and try again: ${strExist?.join(
            ", "
          )} `
        );
        setLoader(false);
      } else {
        reprintLabelsReport({
          currentUser: userName,
          OrderNumbers: odNum,
        })
          .then((res: any) => {
            if (res?.length > 0) {
              setIsReprintEnable(true);
              setReprintModal(false);
              setStream(res);
              setOrderList([]);
              setLoader(false);
              setRowSelection({});
            }
          })
          .catch((error: any) => {
            setErrorMessage(error.response.data?.error?.messages[0]);
            showErrorNotification(error.response.data?.error?.messages[0]);
            setLoader(false);
          });
      }
    } else {
      showErrorNotification("Please enter atleast one order number");
      setLoader(false);
    }
  };

  return (
    <Spin tip="Loading..." spinning={loader}>
      <div className="frame-pick-wrapper">
        <Divider />
        <Breadcrumb>
          <span>Frame Pick</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end">
            <Label label="Select Picker">
              <Select
                placeholder="Select"
                disabled={loader}
                showSearch
                value={pickerId}
                style={{ width: 120 }}
                optionFilterProp="children"
                onSelect={(value: string) => {
                  changePicker(value);
                }}
                size="small"
              >
                {filterValues.finalPickerArr?.map(
                  ({ PickerId, PickerName }: any) => {
                    return (
                      <Option key={PickerId} value={PickerId}>
                        {PickerName}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Label>
            <Button
              size="middle"
              disabled={
                loader || !tableProps.dataSource.length || !selectedRow.length
              }
              onClick={() => printLabelHandler("selected")}
            >
              Print Selected Labels
            </Button>
            <div>
              {hasPermission("framepick_update") && (
                <Button
                  size="middle"
                  onClick={closeModal}
                  disabled={
                    loader || (!selectedRow.length && !notFoundRow.length)
                  }
                >
                  Update Acuity
                </Button>
              )}
            </div>
            <div>
              {hasPermission("framepick_printall") && (
                <Button
                  size="middle"
                  onClick={() => printLabelHandler("all")}
                  disabled={loader || !tableProps.dataSource.length}
                >
                  Print All Labels
                </Button>
              )}
            </div>
            <div>
              <Button
                size="middle"
                onClick={() => setReprintModal(true)}
                disabled={loader}
              >
                Reprint Labels
              </Button>
            </div>
          </div>
        </TableFilter>
        <GenericGrid
          columns={filterValues.columns}
          data={filterValues.data}
          enableRowSelection={true}
          onRowSelectionChange={setRowSelection}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          filterDisplayMode="popover"
          highlightFilterMatch={true}
          paginationDisplayMode="pages"
          paginationProps={{
            color: 'primary',
            shape: 'rounded',
            showRowsPerPage: true,
            variant: 'outlined',
            rowsPerPageOptions: [10, 50, 100, 500, 1000, 5000]
          }}enableStickyHeader={true}
          enableStickyFooter={true}
          enableColumnPinning={true}
          enableColumnOrdering={false}
          columnPinning={{}}
          isLoading={filterValues.loading}
          muiTableBodyCellProps={{
            sx: {
              fontSize: '10px',
              textAlign: 'left',
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              fontSize: '10px',
              fontWeight: '800',
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              textAlign: 'left',
              padding: "5px 10px",
            },
          }}
          muiTableBodyRowProps={{
            sx: {
              "& td": {
                padding: "5px 10px", 
              }
            },
          }}>
        </GenericGrid>
        {stream ? (
          <Modal
            open={true}
            onOk={() => setStream("")}
            onCancel={handleCancel}
            footer={null}
            width={1000}
            destroyOnClose
          >
            <PDFStream
              onPrint={isReprintEnable ? () => { } : onPrint}
              stream={stream}
            />
          </Modal>
        ) : null}

        <Modal
          title="Acuity Update Confirmation"
          okText="Ok"
          open={open}
          centered
          confirmLoading={loader}
          okButtonProps={{ disabled: loader }}
          style={{ top: 20 }}
          onOk={() => acuityClickHandler()}
          onCancel={() => setOpen(false)}
        >
          <span>Are you sure you want to Update Acuity?</span>
        </Modal>
        <Modal
          title="Reprint Order Numbers"
          okText="Print"
          centered
          width={500}
          styles={{
            body: {
              overflowY: "scroll",
              maxHeight: "calc(100vh - 200px)",
              backgroundColor: "#F0F0F0",
            },
          }}
          confirmLoading={loader}
          open={reprintModal}
          onOk={handleOrderList}
          onCancel={() => {
            setReprintModal(false);
            setOrderList([]);
            setErrorMessage("");
          }}
        >
          <span>
            <TextArea
              onChange={(e) => {
                setOrderList(e.target.value);
                setErrorMessage("");
              }}
              value={orderList}
              rows={9}
            ></TextArea>
            <p style={{ color: "red", fontSize: "10px" }}>{errorMessage}</p>
          </span>
        </Modal>
      </div>
    </Spin>
  );
}

export default FramePick;
