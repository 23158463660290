import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Dropdown, Menu, Space } from "antd";
// import styles from './navbarMenu.module.scss';
import styles from "./navbarMenu.module.scss";
import styled from "styled-components";
import { useMsal } from "@azure/msal-react";
import { permission, title } from "process";
import { hasMenuLevelPermissions } from "src/util/utils";
import { useDispatch } from "react-redux";
import {
  ArrowDownOutlined,
  DownCircleFilled,
  DownOutlined,
} from "@ant-design/icons";
import { getMenuPathAndPermissions } from "src/util/utils";
import { useNavigate } from "react-router-dom";

function NavbarMenu({ routes }: any) {
  const navigate = useNavigate();
  const { instance, accounts }: any = useMsal();
  const [isVisible, setIsVisible] = useState(false);
  const [current, setCurrent] = useState("0");
  const [menuItems, setMenuItems] = useState<any>([]);
  const dispatch = useDispatch();
  const updatedRole = localStorage.getItem("updatedRole");
  let adRoles: any = [];
  accounts[0]?.idTokenClaims?.roles?.map((item: any) => {
    adRoles.push(item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
  });

  const roleName = updatedRole ? updatedRole : adRoles.join(",").toLowerCase();
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    if (roleName) {
      dispatch({
        type: "SET_ROLE",
        payload: roleName,
      });
    }
  }, [roleName]);
  useEffect(() => {
    if (!accounts || accounts?.length === 0) {
      return;
    }
    if (window.location.pathname === "/") {
      navigate("/dashboard");
    } else {
      const perm = getMenuPathAndPermissions(roleName);
      setHasPermission(perm);

      if (!perm) {
        navigate("/unauthorized");
      }
    }
  }, [accounts]);

  const getPath = (route: any) => {
    if (
      route?.title.includes("Frame Arranger - ") ||
      route?.title === "Planogram Export"
    ) {
      return (
        <a href={route?.path} target="blank" className={styles["link"]}>
          {route?.title}
        </a>
      );
    } else {
      if (route?.path) {
        return (
          <Link to={`${route?.path?.toLowerCase()}`} className={styles["link"]}>
            {route?.title}
          </Link>
        );
      } else {
        if (route?.title === "DC") {
          return (
            <span className={styles["submenu-heading"]}>
              {route?.title}
            </span>
          );

        } else {
          return (
            <span className={styles["submenu-heading"]}>
              {route?.title}
              <DownCircleFilled style={{ marginLeft: "5px" }} />
            </span>
          );
        }
      }
    }
  };
  const getChild = (children: any) => {
    const subRoutes: any = [];
    children?.map((child: any, index: any) => {
      if (
        accounts?.length > 0 &&
        hasMenuLevelPermissions(child?.title, roleName) &&
        window.location.hostname !== "dcapp.myeyedr.com" &&
        child?.title !== ""
      ) {
        subRoutes.push({
          key: child.title.toLowerCase(),
          icon: getPath(child),
          label: "",
          children: child.children ? getChild(child.children) : undefined, // Recursively call for sub-submenu
        });
      } else {
        if (
          accounts?.length > 0 &&
          hasMenuLevelPermissions(child?.title, roleName) &&
          child?.title !== ""
        ) {
          subRoutes.push({
            key: child.title.toLowerCase(),
            icon: getPath(child),
            label: "",
            children: child.children ? getChild(child.children) : undefined, // Recursively call for sub-submenu
          });
        } else {
          if (
            accounts?.length > 0 &&
            accounts[0]?.username === "tanya.rieth@myeyedr.com" &&
            child?.title === "Frame Arranger" &&
            child?.title !== ""
          ) {
            subRoutes.push({
              key: child.title.toLowerCase(),
              icon: getPath(child),
              label: "",
            });
          }
        }
      }
    });
    return subRoutes;
  };
  useEffect(() => {
    let items: any = [];
    routes?.map((route: any, index: number) => {
      const subRoute = route.children ? getChild(route.children) : "";
      if (
        ((accounts?.length > 0 &&
          accounts[0]?.idTokenClaims?.roles?.includes("admin")) ||
          window.location.hostname !== "dcapp.myeyedr.com") &&
        route?.title !== "" &&
        hasMenuLevelPermissions(route?.title, roleName)
      ) {
        items.push({
          key: route?.title?.toLowerCase(),
          icon: getPath(route),
          label: route?.title,
          children: subRoute,
        });
      } else {
        if (
          accounts?.length > 0 &&
          hasMenuLevelPermissions(route?.title, roleName) &&
          route?.title !== ""
        ) {
          items.push({
            key: route?.title.toLowerCase(),
            icon: getPath(route),
            label: route?.title,
            children: subRoute,
          });
        } else {
          if (
            accounts?.length > 0 &&
            accounts[0]?.username === "tanya.rieth@myeyedr.com" &&
            route?.title === "Frame Arranger" &&
            route?.title !== ""
          ) {
            items.push({
              key: route?.title.toLowerCase(),
              icon: getPath(route),
              label: "",
              children: subRoute,
            });
          }
        }
      }
    });
    setMenuItems([...items]);
  }, [routes, accounts]);

  return (
    <>
      {accounts?.length ? (
        <>
          <Divider />
          <StyledDiv>
            {" "}
            <StyledMenu
              theme="light"
              mode="horizontal"
              onClick={(e) => {
                setIsVisible(false);
                setCurrent(e.key);
              }}
              selectedKeys={[current]}
              className={styles["menu"]}
              items={menuItems}
            ></StyledMenu>
          </StyledDiv>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default NavbarMenu;

const StyledDiv = styled.div`
  width: 100%;
  padding: 10px 20px;
`;

const StyledMenu = styled(Menu)`
  height: 70px;
  border: 1px;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: black;
  background: #f0f0f0;
  font-size: 10px;
`;
