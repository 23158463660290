import { Key, useEffect, useState } from "react";
import { Divider, Spin, Select } from "antd";
import { useDispatch } from "react-redux";
import TableFilter from "../common/TableFilter/TableFilter";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import {
  showErrorNotification,
} from "../../util/notifications";
import Button from "../common/Button/Button";
import filterColumns from "./filterColumns";
import { fetchVendorList } from "src/components/vendor/vendor-sagas";
import { getCollectionByVendor } from "src/components/kits/kitMaintenance/sagas";
import { getOffices } from "src/components/systemPreference/sagas";
import styled from "styled-components";
import { getUserName } from "../common/UserDetails";
import useRolePermission from "src/hooks/useRolePermission";
import GenericGrid from "../common/Grid/GenericGrid";
import { MRT_RowSelectionState } from "material-react-table";


const { Option } = Select;
function OfficeReplenishmentPO() {
  const userName = getUserName();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [vendorId, setVendorId] = useState<any>(undefined);
  const [vendors, setVendors] = useState<any>([]);
  const [collections, setCollections] = useState<any>([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [offices, setOffices] = useState<any>([]);
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const { hasPermission } = useRolePermission();
  let filterValues = filterColumns();

  useEffect(() => {
    getVendorList();
    getOffices({})
      .then((res: any) => {
        const sortedOffice: any = res?.sort((a: any, b: any) => {
          return (
            a.LocationNumber !== null &&
            a.LocationNumber.localeCompare(b.LocationNumber, "en", {
              numeric: true,
            })
          );
        });
        setOffices(sortedOffice);
      })
      .catch((error: any) => {
        showErrorNotification(error?.response?.data?.error?.messages[0]);
      });
  }, []);
  const getVendorList = () => {
    fetchVendorList()
      .then((res: any) => {
        const sortedVendors = res?.sort((a: any, b: any) => {
          return a.VendorName.localeCompare(b.VendorName, "en", {
            numeric: true,
          });
        });
        setVendors(sortedVendors);
      })
      .catch((error: any) => {
        showErrorNotification(error?.response?.data?.error?.messages[0]);
      });
  };
  useEffect(() => {
    if (vendorId) {
      getCollectionByVendor(vendorId).then((res: any) => {
        const sortedCollections: any = res?.sort((a: any, b: any) => {
          return a.Description.localeCompare(b.Description, "sv");
        });
        setCollections(sortedCollections);
      });
    }
  }, [vendorId]);
  const changeVendor = (id: any) => {
    setVendorId(id);
  };
  const handleGeneratePO = () => {
    const payload = {
      OfficeList: selectedOffices.join(","),
      VendorID: vendorId,
      Collections: selectedCollections.join(","),
      UserNasme: userName,
    };
    console.log(payload);
    //ToDo : Call API to fill the grid
  };
  return (
    <Spin spinning={loader}>
      <div>
        <Divider />
        <Breadcrumb>
          <span>Office Replenishment PO</span>
        </Breadcrumb>
        <TableFilter>
          <div className="d-flex gap-2">
            <Select
              placeholder="Select Vendor"
              disabled={loader}
              showSearch
              value={vendorId}
              style={{ width: "200px", height: 32 }}
              optionFilterProp="children"
              onSelect={(value: string) => {
                changeVendor(value);
              }}
              size="small"
            >
              {vendors?.map(
                ({ ID, VendorName }: any) => {
                  return (
                    <Option key={ID} value={ID}>
                      {VendorName}
                    </Option>
                  );
                }
              )}
            </Select>
            <Select
              mode="multiple"
              allowClear
              showSearch={true}
              style={{ width: "200px" }}
              placeholder="Select Collection(s)"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => setSelectedCollections(value)}
              optionLabelProp="label"
              maxTagCount="responsive"
            >
              {collections?.map((item: any, index: Key | null | undefined) => {
                return (
                  <Option
                    key={index}
                    value={item?.ID}
                    label={item?.Description}
                  >
                    {item?.Description}
                  </Option>
                );
              })}
            </Select>
            <Select
              mode="multiple"
              allowClear
              showSearch={true}
              style={{ width: "200px" }}
              placeholder="Select Office(s)"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => setSelectedOffices(value)}
              optionLabelProp="label"
              maxTagCount="responsive"
            >
              {offices?.map((item: any, index: Key | null | undefined) => {
                return (
                  <Option
                    key={index}
                    value={item?.LocationNumber}
                    label={item?.LocationNumber}
                  >
                    {item?.LocationNumber}
                  </Option>
                );
              })}
            </Select>
            <Button
              onClick={handleGeneratePO}
              size="middle"
              disabled={!(selectedOffices.length || selectedCollections.length)}
            >
              Generate PO
            </Button>
          </div>
        </TableFilter>
        <GenericGrid
          columns={filterValues.columns}
          data={filterValues.data}
          enableRowSelection={true}
          onRowSelectionChange={setRowSelection}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          filterDisplayMode="popover"
          highlightFilterMatch={true}
          paginationDisplayMode="pages"
          paginationProps={{
            color: "primary",
            shape: "rounded",
            showRowsPerPage: true,
            variant: "outlined",
            rowsPerPageOptions: [10, 50, 100, 500, 1000],
          }}
          enableStickyHeader={true}
          enableStickyFooter={true}
          enableColumnPinning={true}
          columnPinning={{}}
          isLoading={filterValues.loading}
          muiTableBodyCellProps={{
            sx: {
              fontSize: "10px",
              textAlign: "left",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              fontSize: "10px",
              fontWeight: "800",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              textAlign: "left",
              padding: "5px 10px",
            },
          }}
          muiTableBodyRowProps={{
            sx: {
              "& td": {
                padding: "5px 10px",
              },
            },
          }}
        ></GenericGrid>
      </div>
    </Spin>
  );
}
const StyledDiv = styled.div`
  color: #fff;
  position: absolute;
  left: 24%;
  font-size: 16px;
`;
export default OfficeReplenishmentPO;