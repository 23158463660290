import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION } from "../../util/utils";
import { formatDate } from "src/util/date";

export default function filterColumns() {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const vendors: any | null | undefined = [];
  const collections: any | null | undefined = [];
  data?.map((row: any) => {
    vendors.push({ text: row.VendorName, value: row.VendorName });
  });

  data?.map((row: any) => {
    collections.push({ text: row.CollectionName, value: row.CollectionName})
  });

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind === vendors.findIndex((elem: any) => elem.text === ele.text)
  );

  let collectionName = collections.filter(
    (ele: any, ind: any) =>
      ind === collections.findIndex((elem: any) => elem.text === ele.text)
  );

  const columns = [
    {
      id: "ID",
      header: "ID",
      accessorKey: "ID",
      size: 100,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "OFFICE NAME",
      accessorKey: "LocationName",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "OFFICE NUMBER",
      accessorKey: "OfficeNum",
      size: 135,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "VENDOR",
      accessorKey: "VendorName",
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "COLLECTION",
      accessorKey: "CollectionName",
      size: 110,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "MODEL",
      accessorKey: "Model",
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "COLOR",
      accessorKey: "ColorNumber",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "COLOR DESCRIPTION",
      accessorKey: "ColorDescription",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "EYE SIZE",
      accessorKey: "EyeSize",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "UPC",
      accessorKey: "UPC",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "SKU QUANTITY",
      accessorKey: "SKUQty",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "FRAME TYPE",
      accessorKey: "FrameType",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "MED COST",
      accessorKey: "MEDCost",
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const med = cell.row?.original?.MEDCost;
        return med ? "$" + med : "$0";
      },
    },
    {
      header: "EFFECTIVE DATE",
      accessorKey: "EffectiveDate",
      size: 120,
      enableColumnFilter: false,
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const effectiveDate = cell.row?.original.EffectiveDate;
        return effectiveDate ? formatDate(effectiveDate) : "N/A";
      },
    },
    {
      header: "ARRANGER QUANTITY",
      accessorKey: "ArrangerQuantity",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
  ];

  return { columns, data, loading, vendorName };
}
